import { Resident } from '../store/models/domain';


abstract class ResidentService {  
        
    public static displayName(resident: Partial<Resident>): string {

        return `${!!resident.namePrefix ? resident.namePrefix + ' ' : ''} ${resident.firstName} ${resident.lastName} ${!!resident.nickName ? ' (' + resident.nickName + ')' : ''}`;        
    }

    public static simpleDisplayName(resident: Partial<Resident>): string {

        return `${resident.firstName} ${resident.lastName}`;        
    }
}

export default ResidentService;
