import * as React from 'react';
import { withTranslation } from 'react-i18next';
import {Row, Col, Form, Card, CardGroup, Button} from 'react-bootstrap';
import { HKReport } from '../../../store/models/domain';
import ResidentService from '../../../services/ResidentService';
import SignaturePad from 'react-signature-canvas';



interface Props  {
    report?: HKReport,
    cleanType?: string,
    readonly?: boolean
    saveLabel: string,
    cancelLabel: string,
    onCancel: () => void,
    onSave: (declineSignature?: string) => void,

    t: any
}

const DeclineSignatureForm = (props: Props) => {
        const {
         
    
            // Form props
            cancelLabel,
            saveLabel,
            onCancel,       
            onSave,
            report, 
            readonly,
            cleanType,
    
            // Translate props
            t,
        } = props;

        const ref = React.useRef<any>(null);
        const [isEmpty, setIsEmpty] = React.useState(false);

        const handleDecline = React.useCallback(() => {

            const isEmpty = ref.current.isEmpty();
            if(isEmpty){
                setIsEmpty(true);
                return;
            }

            const dataUri = ref.current.toDataURL();

            onSave(dataUri);
           
        }, [ref, report, onSave]);

        React.useEffect(() => {
            if (ref.current) {
                if (readonly) {
                    ref.current.off();
                } else {
                    ref.current.on();
                }
            }

            if (report?.declineSignature) {
                ref.current.fromDataURL(report.declineSignature);
            }
        }, [readonly, report?.declineSignature]);
        
    
        return (
            <Row className="justify-content-md-center">
                <Col sm={12} md={12}>                    
                    <Form className="p-1">
                        
                        <Form.Row className="mb-2"><Col><h3 className="pull-left font-weight-bolder">{t('I, {{resident}}, hereby decline a {{cleanType}} cleaning for my room, {{roomNumber}}, on {{today}}', 
                            {
                                resident: ResidentService.simpleDisplayName(report?.resident || {}),
                                cleanType: cleanType,
                                roomNumber: report?.room?.number,
                                today: new Date().toLocaleDateString()
                            })}
                        </h3></Col></Form.Row>
                        
    
                        <Form.Group>                            
                            <SignaturePad canvasProps={{ className: 'sigPad' }} ref={ref} clearOnResize={false} />
                            <div className='sigLine'/>  
                            <h3 className="font-weight-bolder">{t('signature')}</h3>
                            {isEmpty && <Row><Col><small className="text-danger">{t('Signature is required')}</small></Col></Row>}
                        </Form.Group>

                        

                      
                        {/* Mimik sweetalert2 buttons */}
                        <div className="d-flex justify-content-between align-items-center mt-1 text-nowrap align-middle" >
                            
                            
                            <Button className="lightPinkButton declineFormButton" type="button" onClick={() => { onCancel() }} >{cancelLabel}</Button>
                            <Button className="redButton declineFormButton" type="button"  onClick={() => ref.current.clear() } disabled={readonly} >{t('Clear Signature')}</Button>
                            <Button className="lightBlueButton declineFormButton" type="button"  onClick={() => { handleDecline() }} disabled={readonly} >{saveLabel}</Button>
                        </div>   
                    </Form>
                </Col>
            </Row>
        );
    };
    
    const translate = withTranslation();
    export default translate(DeclineSignatureForm);
