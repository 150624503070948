import * as React from 'react';
import { Dropdown, Form, Button, Alert } from 'react-bootstrap';
import classnames from "classnames";
import moment from "moment";
import Image from '../../../components/Image';
import Icon from '../../../components/Icon';
import '../housekeeping.scss'
import _ from "lodash";

import { TenantData, HKCleanType, HKReportStatus, NoteType, CleanOperationImageRef, CleanOperationImage, HKAuditOperationStatus, HKAuditReport } from '../../../../store/models/domain'
import Swal, {runWithProgress, SwalForm} from '../../../components/Alert';
import * as actions from '../reducer'
import store from "../../../../store/store"
import { saveHousekeepingReport, updateHKReportProps, saveAuditMarkingReport } from "../../../../store/reducers/domain/housekeeping"
import ResidentService from '../../../../services/ResidentService';
import CommentAndPhotosForm from '../CommentAndPhotosForm'
import SupervisorNoteForm from '../SupervisorNoteForm'
import { imageCache } from "../../../../common/dataCache";
import { authProvider } from "../../../../common/auth/AuthProvider";
import DEMO from "../../../../store/constant";
import OperationTaskDetails from "../OperationTaskDetails";
import apiService from "../../../../common/api.service"
import ColorUtils from "../../../../utils/ColorUtils"
import EmailRecipientForm from './EmailRecipientForm'
import DeclineSignatureForm from '../DeclineSignatureForm'

interface Props {
    state: actions.CompState
    dispatch: actions.HkDispatch
    t: any,
    i18n: any
}

interface ShortcutComp {
    id: string
    text: string
    icon?: string
    color?: string
    className?: string
    onClick?: (e: any) => void
}

interface OpGroup {
    group: string
    status: string
}

const _addLineBreaks = (string: string) => string.split('\n').map((text, index) => (
    <React.Fragment key={`${text}-${index}`}>
        {text}
        <br />
    </React.Fragment>
));

const HousekeepingDashboard = (props: Props) => {
    const {
        i18n,
        t, //Translation

        dispatch,
        state
    }: Props = props

    const isSupervisor = authProvider.hasRole(authProvider.Roles.Nursing);
    const HKReport = state.cleaningRecord ?? {} as actions.HKReportExt
    const room = state.rooms.byId[HKReport?.room.id]
    const roomType = HKReport.roomType
    const resident = HKReport.resident
    const routine = HKReport?.roomType.routines?.find(r => r.cleanType === HKReport.selectedCleanType)
    const shortcuts = roomType.routines.filter(r => r.shortcut).sort((a, b) => (a.shortcut?.order ?? 9999) - (b.shortcut?.order ?? 9999))
    const allGroups = [...new Set(routine?.operations.map(r => r.group))]
    const groupList: OpGroup[] = []
    allGroups.forEach(g => {
        let incomplete = routine?.operations.find((r: actions.HKCleanOperationExt) => r.group === g && r.skipped === true) ? true : false
        let complete = routine?.operations.find((r: actions.HKCleanOperationExt) => r.group === g && r.checked === undefined) ? false : true

        groupList.push({
            group: g,
            status: incomplete ? "incomplete" : complete ? "complete" : "pending"
        })
    })
    
    let lastOperation = HKReport.auditMode 
        ? routine?.operations.find((r: actions.HKCleanOperationExt) => (r.auditStatus ?? HKAuditOperationStatus.NotObserved) === HKAuditOperationStatus.NotObserved) //Next un-obsered operation
            ?? routine?.operations.find(() => true)
        : routine?.operations.find((r: actions.HKCleanOperationExt) => r.checked === undefined) //Next un-checked operation
    if (lastOperation && lastOperation.group === HKReport.selectedGroup) {
        dispatch(actions.selectCleanOpGroup(undefined))
    }

    let selectedGroup = allGroups[0]
    if (HKReport.selectedGroup) selectedGroup = HKReport.selectedGroup
    else {
        if (lastOperation) selectedGroup = lastOperation.group
        else selectedGroup = allGroups[allGroups.length - 1]
    }

    let operations = (routine?.operations.map((r: actions.HKCleanOperationExt, i) => { return { ...r, index: i } }).filter(r => r.group === selectedGroup) as actions.HKCleanOperationExt[]) ?? []
    let nextOperation = HKReport.auditMode 
        ? operations.find((r: actions.HKCleanOperationExt) => HKReport.auditMarking 
            ? r.isMarked === undefined // For audit marking mode next un-marked operation
            : (r.auditStatus ?? HKAuditOperationStatus.NotObserved) === HKAuditOperationStatus.NotObserved) //Next un-observed operation
        : operations.find((r: actions.HKCleanOperationExt) => r.group === selectedGroup && r.checked === undefined) //Next un-checked operation
    let reportState = routine?.operations.find((r: actions.HKCleanOperationExt) => r.checked === undefined) ?
        (routine?.operations.find((r: actions.HKCleanOperationExt) => r.skipped === true) ? "incomplete" : "pending") : "complete"

    const _cancel = () => {
        if (HKReport.readonly === true) {
            dispatch(actions.selectRoom(undefined, undefined));
            return;
        }

        Swal.fire({
            title: t('Are you sure?'),
            text: t("Changes will be lost."),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('Yes, Cancel.'),
            cancelButtonText: t('No, Take me back!')
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(actions.selectRoom(undefined, undefined))
            }
        })
    }

    const _hideShortcuts = () => {
        dispatch(actions.toggleShortcuts(false))
    }

    const _save = (state: string) => {
        if (HKReport.readonly !== true) {
            _saveCore(state);
        }
    }

    const _roomCheckedClean = () => {
        if (HKReport.readonly === true) return;

        const beforeSave = (report: actions.HKReportExt) => {
            return {
                ...report,
                comment: "Room checked & CLEAN"
            }
        }

        _saveCore('complete', beforeSave);
    }

    const _roomOccupied = () => {
        if (HKReport.readonly === true) return;

        var now = new Date();
        const beforeSave = (report: actions.HKReportExt) => {
            return {
                ...report,
                comment: "Room occupied - " + now.toLocaleDateString()
            }
        }

        _saveCore('incomplete', beforeSave);
    }

    const _editComment = () => {
        
        Promise.all((HKReport.photos ?? []).map(p => { 
            const request = `files/${p.photo}`;
            return imageCache.getText(request);
        }))
        .then(imageCache => {
            SwalForm.fire({            
                title:t('Housekeeper comments & photos'),           
                html: (<CommentAndPhotosForm 
                    values={{...state.cleaningRecord, readonly: HKReport.readonly || HKReport.auditMode}}   
                    imageCache={(state.cleaningRecord?.photos ?? []).reduce((obj, cur, i) => { return { ...obj, [cur.id]: imageCache[i]} }, {}) ?? {}}            
                    saveLabel={t("Save")}
                    cancelLabel={t("Cancel")}
                    canCancel={true}                
                    onSave={(values: any) => {
                        Swal.close();               
                        
                        dispatch(actions.updateCommentAndPhotos({
                            comment: values.comment,
                            residentFamilyConcern: values.residentFamilyConcern,
                            maintenance: values.maintenance,
                            pestControl: values.pestControl
                        }, values.photos))
                    }}
                    onCancel={(values: any) => {Swal.close()}} 
                    onSendEmail={HKReport.auditMarking ? undefined : (values: any) => _sendEmail(values)}
                    onDeclineCleaning={HKReport.auditMarking ? undefined : (values: any) => _showDeclineForm(values)}
                    />
                )            
            });
        });
    }

    const _editAuditComment = () => {
        
        Promise.all((HKReport.photos ?? []).map(p => { 
            const request = `files/${p.photo}`;
            return imageCache.getText(request);
        }))
        .then(imageCache => {
            SwalForm.fire({            
                title:t('Auditor comments & photos'),           
                html: (<CommentAndPhotosForm 
                    values={{...HKReport.auditReport, room: HKReport.room, readonly: HKReport.readonly || !HKReport.auditMode}}   
                    hideCategories={true}
                    imageCache={(HKReport.auditReport?.photos ?? []).reduce((obj, cur, i) => { return { ...obj, [cur.id]: imageCache[i]} }, {}) ?? {}}            
                    saveLabel={t("Save")}
                    cancelLabel={t("Cancel")}
                    canCancel={true}                
                    onSave={(values: any) => {
                        Swal.close();               
                        
                        dispatch(actions.updateAudit({
                            comment: values.comment,
                            photos: values.photos
                        }))
                    }}
                    onCancel={(values: any) => {Swal.close()}} 
                    onSendEmail={HKReport.auditMarking ? undefined : (values: any) => _sendAuditEmail(values)}
                    />
                )            
            });
        });
    }

    const _editSupervisorNote = () => {
        
        SwalForm.fire({            
            title:t('Auditor Note'),           
            html: (<SupervisorNoteForm 
                values={{...HKReport, readonly: !isSupervisor}}                           
                saveLabel={isSupervisor ? t("Save") : t('Close')}
                cancelLabel={t("Cancel")}
                canCancel={true}                
                onSave={(values: any) => {
                    Swal.close(); 
                    
                    let trimmedReport = {
                        id: HKReport.id,
                        supervisorNote: values.supervisorNote                        
                    }
                    
                    runWithProgress(t("Updating Auditor Note..."), () => {
                        store.dispatch(updateHKReportProps(trimmedReport)).then(r => {
                            if (!updateHKReportProps.fulfilled.match(r)) {
                                throw r.error;
                            }
                            
                            dispatch(actions.updateSupervisorNote(values.supervisorNote))
            
                            Swal.fire({
                                icon: 'success',
                                title: t('Auditor Note updated.'),
                                showConfirmButton: false,
                                timer: 800
                            })
                        }).catch(_handleError)
                    });
                }}
                onCancel={(values: any) => {Swal.close()}} />
            )            
        });
    }

    const _showTaskDetails = React.useCallback((state: actions.CompState, opItem: actions.HKCleanOperationExt) => {

        let formValues = {
            images: (opItem.detailImages ?? []).filter(r => r.locale === i18n.language)
                .map((r: CleanOperationImageRef) => ({ref: r, image: _.first((state.operationImages).filter(i => i.image === r.image))}))
                .filter((r: any) => !!r.image)
                .map((r: any) => ({...r.image, locale: r.ref.locale})) as CleanOperationImage[]
        };


        if(formValues.images.length === 0)
            return;

        SwalForm.fire({            
            html: (<OperationTaskDetails
                values={formValues}               
                submitLabel={t("Save")}
                cancelLabel={t("Close")}
                onSubmit={async (values: any) => {}}
                onCancel={(values: any) => { Swal.close() }} />
            ),
            width: '100vw'
        })
    }, [t])

    const _handleError = (error: any) => {        
        Swal.fire({
            icon: 'error',
            title: t('Error: ' + (error.response?.data?.errors || error.message)),
            showConfirmButton: true
        })
    }

    const _saveCore = (state: string, beforeSave?: (report: actions.HKReportExt) => actions.HKReportExt) => {

        if(!authProvider.getAccountInfo()?.staffMember) {
            Swal.fire({
                icon: 'warning',
                title: !HKReport.auditMarking 
                    ? t('Current user does not have permission to save cleaning reports.')
                    : t('Current user does not have permission to save Pre-Audit Marking reports.'),
                showConfirmButton: true,
                confirmButtonText: t('Ok')
            });
            return;
        }

        let trimmedReport = {
            ...HKReport,
            roomType: {
                ...HKReport.roomType,
                routines: HKReport.roomType.routines?.filter(r => r.cleanType === HKReport?.selectedCleanType)
            }
        }

        if(HKReport.auditMode) {
            if(!HKReport.auditMarking) {
                trimmedReport = { 
                    ...trimmedReport,              
                    auditReport: {
                        ...HKReport.auditReport!,
                        reportDate: moment().format(actions.DATETIME_FORMAT)
                    },
                    // Set unset audit status to default
                    roomType: {
                        ...HKReport.roomType,
                        routines: HKReport.roomType.routines?.map(r => ({
                            ...r,
                            operations: r.operations.map(o => (
                                {
                                    ...o, 
                                    auditStatus: o.auditStatus || 
                                        (!!HKReport.markingReport && !o.isMarked  ? HKAuditOperationStatus.NotObserved : HKAuditOperationStatus.CleanedPass)
                                }))
                        }))
                    }
                }
            }
        } else {
            trimmedReport = { 
                ...trimmedReport,              
                status: state === 'declined' ? HKReportStatus.Declined : (state === 'complete' ? HKReportStatus.Complete : HKReportStatus.Incomplete),            
                reportDate: moment().format(actions.DATETIME_FORMAT)
            }
        }

        if (beforeSave) {
            trimmedReport = beforeSave(trimmedReport)
        }

        if(HKReport.auditMarking) {
            return runWithProgress(t("Saving Pre-Audit Marking Report..."), () => {
                store.dispatch(saveAuditMarkingReport(trimmedReport)).then(r => {
                    if (!saveAuditMarkingReport.fulfilled.match(r)) {
                        throw r.error;
                    }
    
                    Swal.fire({
                        icon: 'success',
                        title: t('Pre-Audit Marking has been saved.'),
                        showConfirmButton: false,
                        timer: 800
                    }).then(() => {
                        dispatch(actions.selectRoom(undefined, undefined))
                    })
                }).catch(_handleError)
            });
        }

        return runWithProgress(t("Saving..."), () => {
            store.dispatch(saveHousekeepingReport(trimmedReport)).then(r => {
                if (!saveHousekeepingReport.fulfilled.match(r)) {
                    throw r.error;
                }

                Swal.fire({
                    icon: 'success',
                    title: t('Saved.'),
                    showConfirmButton: false,
                    timer: 800
                }).then(() => {
                    dispatch(actions.selectRoom(undefined, undefined))
                })
            }).catch(_handleError)
        });
    }

    
    const _sendEmail = (values: any) => {

        dispatch(actions.updateCommentAndPhotos({
            comment: values.comment,
            residentFamilyConcern: values.residentFamilyConcern,
            maintenance: values.maintenance,
            pestControl: values.pestControl
        }, values.photos));

        SwalForm.fire({
            title: t("Send Email"),
            html: (<EmailRecipientForm
                tenant={state.tenant}
                submitLabel={t("Send")}
                cancelLabel={t("Cancel")}
                onSave={(sel: any) => {
                    runWithProgress(t("Sending Housekeeper comments & photos..."), () => {

                        const sendEmail = () =>
                            apiService.post<any, any>("housekeeping/emailreport",
                                {
                                    id: HKReport.id,
                                    facilityId: authProvider.getAccountInfo()?.facility.id,
                                    recipients: sel.selectedEmails
                                })
                                .then(r => Swal.fire({
                                    icon: 'success',
                                    title: t('Successfully sent.'),
                                    showConfirmButton: false,
                                    timer: 800
                                }))
                                .catch(_handleError);

                        if (!!authProvider.getAccountInfo()?.staffMember && HKReport.readonly !== true && HKReport.auditMode !== true) {

                            // Save report
                            let trimmedReport = {
                                ...HKReport,
                                status: reportState === 'complete' ? HKReportStatus.Complete : HKReportStatus.Incomplete,
                                roomType: {
                                    ...HKReport.roomType,
                                    routines: HKReport.roomType.routines?.filter(r => r.cleanType === HKReport?.selectedCleanType)
                                },
                                reportDate: moment().format(actions.DATETIME_FORMAT),
                                comment: values.comment,
                                residentFamilyConcern: values.residentFamilyConcern,
                                maintenance: values.maintenance,
                                pestControl: values.pestControl,
                                photos: values.photos
                            }

                            store.dispatch(saveHousekeepingReport(trimmedReport)).then(r => {
                                if (!saveHousekeepingReport.fulfilled.match(r)) {
                                    throw r.error;
                                }
                            })
                                .then(r => sendEmail())
                        } else {
                            sendEmail();
                        }
                    });
                }}
                onCancel={(values: any) => { _editComment(); }} />
            )
        })
    }


    const _showDeclineForm = (values: any) => {

        dispatch(actions.updateCommentAndPhotos({
            comment: values.comment,
            residentFamilyConcern: values.residentFamilyConcern,
            maintenance: values.maintenance,
            pestControl: values.pestControl
        }, values.photos));

        SwalForm.fire({
            title: undefined,
            width: '90vw',
            customClass: {
                popup: 'declineFormBorder'
            },
            html: (<DeclineSignatureForm
                report={state.cleaningRecord}
                cleanType={state.cleaningRecord?.selectedCleanType}
                readonly={state.cleaningRecord?.readonly}
                saveLabel={t("Save")}
                cancelLabel={t("Cancel")}
                onSave={(declineSignature?: string) => {
                    if (HKReport.readonly !== true) {
                        _saveCore('declined', (report: actions.HKReportExt) => ({
                            ...report, 
                            declineSignature: declineSignature,
                            comment: (!!report.comment ? (report.comment + '\r\n') : '' ) + 'Room declined for cleaning by resident',
                        }));
                    }
                }}
                onCancel={() => { _editComment(); }} />
            )
        })
    }


    const _sendAuditEmail = (values: any) => {

        dispatch(actions.updateAudit({
            comment: values.comment,
            photos: values.photos
        }));

        SwalForm.fire({
            title: t("Send Email"),
            html: (<EmailRecipientForm
                tenant={state.tenant}
                submitLabel={t("Send")}
                cancelLabel={t("Cancel")}
                onSave={(sel: any) => {
                    runWithProgress(t("Sending Auditor comments & photos..."), () => {

                        const sendEmail = () =>
                            apiService.post<any, any>("housekeeping/emailauditreport",
                                {
                                    id: HKReport.id,
                                    facilityId: authProvider.getAccountInfo()?.facility.id,
                                    recipients: sel.selectedEmails
                                })
                                .then(r => Swal.fire({
                                    icon: 'success',
                                    title: t('Successfully sent.'),
                                    showConfirmButton: false,
                                    timer: 800
                                }))
                                .catch(_handleError);

                        if (!!authProvider.getAccountInfo()?.staffMember && HKReport.readonly !== true && HKReport.auditMode === true) {

                            // Save report
                            let trimmedReport = {
                                ...HKReport,          
                                auditReport: {
                                    ...HKReport.auditReport!,
                                    reportDate: moment().format(actions.DATETIME_FORMAT),
                                    comment: values.comment,
                                    photos: values.photos
                                },
                                // Set unset audit status to default
                                roomType: {
                                    ...HKReport.roomType,
                                    routines: HKReport.roomType.routines?.map(r => ({
                                        ...r,
                                        operations: r.operations.map(o => (
                                            {
                                                ...o, 
                                                auditStatus: o.auditStatus || 
                                                    (!!HKReport.markingReport && !o.isMarked  ? HKAuditOperationStatus.NotObserved : HKAuditOperationStatus.CleanedPass)
                                            }))
                                    }))
                                }
                            }

                            store.dispatch(saveHousekeepingReport(trimmedReport)).then(r => {
                                if (!saveHousekeepingReport.fulfilled.match(r)) {
                                    throw r.error;
                                }
                            })
                                .then(r => sendEmail())
                        } else {
                            sendEmail();
                        }
                    });
                }}
                onCancel={(values: any) => { _editComment(); }} />
            )
        })
    }

    const checkItem = (operation: actions.HKCleanOperationExt, index: number | undefined) => {
        if (index === undefined || HKReport.readonly === true) return
        const id = "op_" + index
        let element = document.getElementById(id)
        element = element?.firstElementChild as HTMLElement
        if (element) {
            try {
                var task = element.animate([
                    { transform: window.getComputedStyle(element).transform },
                    { transform: 'rotateY(180deg)' }
                ], {
                    duration: 300,
                    iterations: 1,
                })
                if (task && task.finished) {
                    task.finished.then(() => {
                        dispatch(actions.toggleCleanOperation(index, operation.checked ? !operation.checked : true))
                    })
                } else {
                    //For samsung browsers
                    setTimeout(() => {
                        dispatch(actions.toggleCleanOperation(index, operation.checked ? !operation.checked : true))
                    }, 300)
                }
            } catch (err) {
                //For browsers that don't support animation
                dispatch(actions.toggleCleanOperation(index, operation.checked ? !operation.checked : true))
            }
        }
    }

    const setOperationAuditStatus = (index: number | undefined, auditStatus: HKAuditOperationStatus) => {        
        if (index === undefined || HKReport.readonly === true) return
        
        dispatch(actions.setOperationAuditStatus(index, auditStatus));
    }

    const setOperationAuditMarked = (index: number | undefined, isMarked: boolean) => {        
        if (index === undefined || HKReport.readonly === true) return
        
        dispatch(actions.setOperationAuditMarked(index, isMarked));
    }

    const updateAudit = (auditReport: Partial<HKAuditReport>) => {        
        if (HKReport.readonly === true) return
        
        dispatch(actions.updateAudit(auditReport));
    }

    const switchClean = (item: HKCleanType) => {
        dispatch(actions.selectCleanType(item.cleanType))
    }

    const selectGroup = (group: string | undefined) => {
        dispatch(actions.selectCleanOpGroup(group))
    }

    const checkAllInGroup = (opGroup: OpGroup) => {
        if (!(state.settings?.allowGroupSelect === true)) return

        if (HKReport.selectedGroupTime) {
            var lastSelected = new Date(HKReport.selectedGroupTime)
            var currentTime = new Date()
            var seconds = (currentTime.getTime() - lastSelected.getTime()) / 1000;
            if (seconds > 2 && seconds < 5) {
                dispatch(actions.toggleCleanOperationGroup(opGroup.group, true))
            } else if (seconds >= 5) {
                dispatch(actions.selectCleanOpGroup(opGroup.group))
            }
        } else {
            dispatch(actions.selectCleanOpGroup(opGroup.group))
        }
    }

    const scrollView = React.useRef<HTMLDivElement>(null)
        React.useEffect(() => {
            if (nextOperation && !HKReport.auditMode) {
                const id = "op_" + nextOperation.index
                const element = document.getElementById(id)
                if (element && scrollView.current) {
                    element.scrollIntoView()
                }
            }
    }, [nextOperation])    

    const CleanTypeRenderItem = (props: {style?: any}) => {
        return (
            <Dropdown style={{ marginTop: '-2px' }} >
                <Dropdown.Toggle className="m-0 p-0 font-weight-bold" variant={'link'} id="dropdown-basic" style={props.style || {}}>{routine?.cleanType}</Dropdown.Toggle>
                {/* Allow change clean type only if not in audit mode, still allow for auditMarking */}
                {(HKReport.auditMode !== true || HKReport.auditMarking) && <ul className="m-0">
                    <Dropdown.Menu>
                        {roomType.routines?.map((item) => (
                            <li key={item.cleanType}><a className="dropdown-item" onClick={(e) => { e.preventDefault(); switchClean(item); }} href={DEMO.BLANK_LINK} id={"ct_" + item.cleanType}>{item.cleanType}</a></li>
                        ))}
                    </Dropdown.Menu>
                </ul>}
            </Dropdown>
        );
    };

    const ShortcutBox: React.FunctionComponent<ShortcutComp> = ({ id, text, icon, color, className, onClick }) => {
        return (
            <div id={"sc-" + id} className={classnames("shortcutBox m-2", className)} onClick={onClick ?? ((e) => { })} >
                <div className="operationBoxInner" style={{ backgroundColor: color ?? "#FFFF99" }}>
                    <div className={classnames("operationBoxNow unselectable")}>
                        <div className={classnames("shortcutBoxIcon px-2 text-center")}>
                            <Icon icon={icon ?? "feather icon-wind"} />
                        </div>
                        <span className={classnames("operationBoxText text-center")}>{t(text)}</span>
                    </div>
                </div>
            </div>
        )
    }

    

    const OperationBox = (
            { 
                operation, isSelected, styleClass: styleClass, flipStyleClass,
                onClick, onShowTaskDetails
            } : 
            {
                operation: actions.HKCleanOperationExt,
                isSelected: boolean,
                styleClass: string,
                flipStyleClass: string,
                onClick: () => void,
                onShowTaskDetails: () => void
            }) => {
        return (
            <div id={'op_' + operation.index} className={classnames("operationBox m-2", {operationBoxSelected: isSelected})} 
                onClick={(e) => onClick()} >
                <div className="operationBoxInner">
                    <div className={classnames("operationBoxNow unselectable", styleClass)}>
                        <div className={classnames("px-2 text-center operationBoxIcon", styleClass)}>
                            <Icon icon={operation.icon ?? "feather icon-wind"} style={{ width: '90px', height: '90px', marginTop: '10px' }} />
                        </div>
                        {(operation?.detailImages || []).length > 0 &&<div className={classnames("iconButton operationDetailsButton d-flex align-items-center justify-content-center")}
                                onClick={(e) => {if(operation) onShowTaskDetails()}}>
                            <i className={classnames("fa fa-question-circle-o fa-2x")} title={t("Task Details")} />
                        </div>}
                        <span className={classnames("p-2 text-center operationBoxText", styleClass)}>{operation.item}</span>
                    </div>
                    <div className={classnames("operationBoxFlip unselectable", flipStyleClass)}>
                        <div className={classnames("px-2 text-center operationBoxIcon", flipStyleClass)}>
                            <Icon icon={operation.icon ?? "feather icon-wind"} style={{ width: '90px', height: '90px', marginTop: '10px' }} />
                        </div>
                        {(operation?.detailImages || []).length > 0 &&<div className={classnames("iconButton operationDetailsButton d-flex align-items-center justify-content-center")}
                                onClick={(e) => {if(operation) onShowTaskDetails()}}>
                            <i className={classnames("fa fa-question-circle-o fa-2x")} title={t("Task Details")} />
                        </div>}
                        <span className={classnames("p-2 text-center operationBoxText", flipStyleClass)}>{operation.item}</span>
                    </div>
                </div>
            </div>
        )
    }    

    const AuditOperationBox = (
        { 
            operation, styleClass: styleClass, flipStyleClass,
            onSetAuditStatus, onShowTaskDetails, auditMarkingMode, onSetAuditMarking
        } : 
        {
            operation: actions.HKCleanOperationExt,            
            styleClass: string,
            flipStyleClass: string,
            onSetAuditStatus: (auditStatus: HKAuditOperationStatus) => void,
            onShowTaskDetails: () => void,
            auditMarkingMode?: boolean,
            onSetAuditMarking: (isMarked: boolean) => void,
        }) => {
            
        const isMarked = !auditMarkingMode && !!HKReport.markingReport && operation.isMarked;
        const isNotMarked = !auditMarkingMode && !!HKReport.markingReport && !operation.isMarked;
        const auditStatus = operation.auditStatus 
            ?? (auditMarkingMode || !!HKReport.markingReport && !operation.isMarked  ? HKAuditOperationStatus.NotObserved : HKAuditOperationStatus.CleanedPass);
        

        return (
            <div className='d-flex flex-column align-items-center m-1'>
                <div className="dashaboard_AuditBox" onClick={e => {
                    if (auditMarkingMode) return;

                    let nextStatus = auditStatus;
                    switch(auditStatus){
                        case HKAuditOperationStatus.CleanedPass: 
                            nextStatus = HKAuditOperationStatus.CleanedFailed;
                            break;
                        case HKAuditOperationStatus.CleanedFailed: 
                            nextStatus = HKAuditOperationStatus.NotCleanedFailed;
                            break;
                        case HKAuditOperationStatus.NotCleanedFailed: 
                            nextStatus = HKAuditOperationStatus.NotObserved;
                            break;
                        case HKAuditOperationStatus.NotObserved: 
                            nextStatus = HKAuditOperationStatus.CleanedPass;
                            break;
                    }
                    onSetAuditStatus(nextStatus);
                }}>
                    <div className={classnames("AuditBox_overlay", {
                        "AuditBox_overlay-pass": auditStatus === HKAuditOperationStatus.CleanedPass,
                        "AuditBox_overlay-unobserved": auditStatus === HKAuditOperationStatus.NotObserved,
                        "AuditBox_overlay-fail": (auditStatus === HKAuditOperationStatus.CleanedFailed || auditStatus === HKAuditOperationStatus.NotCleanedFailed),                        
                    })} />

                    <OperationBox operation={operation} isSelected={false} 
                        styleClass={styleClass} flipStyleClass={flipStyleClass} 
                        onClick={() => {}}
                        onShowTaskDetails={() => onShowTaskDetails()} /> 
                </div>

                <div className='m-2'>                    
                    <div>
                        {[
                            {label: t('Cleaned, Pass'), status: HKAuditOperationStatus.CleanedPass},
                            {label: t('Cleaned, Fail'), status: HKAuditOperationStatus.CleanedFailed},
                            {label: t('Not Cleaned, Fail'), status: HKAuditOperationStatus.NotCleanedFailed},
                            {label: t('Not observed'), status: HKAuditOperationStatus.NotObserved}
                        ].map((item, i) => (
                            <Form.Check key={i} id={`audit_${operation.index}_${item.status}`} type="radio"  
                                    disabled={auditMarkingMode}
                                    label={item.label}  
                                    onChange={(e: any) => {                             
                                        if(!(auditMarkingMode)){       
                                            onSetAuditStatus(item.status);
                                        }
                                    }} 
                                    checked={auditStatus === item.status} />
                        ))}                    
                    </div>

                    {(auditMarkingMode)
                        && <Form.Check type="checkbox" id={`audit_${operation.index}_marked`}
                            className='mt-2'                            
                            label={t("Marked")}  
                            onChange={(e: any) => {
                                if(auditMarkingMode){
                                    onSetAuditMarking(e.target.checked)
                                }
                            }} 
                            checked={operation.isMarked} />}

                    {(!auditMarkingMode && operation.isMarked)
                        && <div className={classnames("p-2 font-weight-bold")}>{'Marked'}</div>}
                </div>
            </div>
        )
}    

    return (
        <>
            <div className={classnames("d-flex titleBar")} style={{ 
                backgroundColor: routine?.color ?? "transparent", 
                color: !!routine?.color ? ColorUtils.getContrastColor(routine.color) : 'black'
                }} >
                <div className="residentPhoto phone-hide" >
                    {!!resident?.photo ? (<Image srcFile={resident.photo} hideBorder={true} />) : (<i className="feather icon-user" />)}

                    {resident.fallRisk === true && <div className="roomBoxIcon CLiconF"><p>F</p></div>}
                    {resident.exitSeeking === true && <div className="roomBoxIcon CLiconE"><p>E</p></div>}
                    {resident.sexuallyAbusive === true && <div className="roomBoxIcon CLiconS checklistIcon"><p>S</p></div>}
                    {resident.verballyAbusive === true && <div className="roomBoxIcon CLiconV checklistIcon"><p>V</p></div>}
                    {resident.physicallyAggressive === true && <div className="roomBoxIcon CLiconP checklistIcon"><p>P</p></div>}

                </div>
                <div className="room-report-title">
                    <div className="phone-hide">{!resident.firstName ? t("Unoccupied") : ResidentService.displayName(resident)}</div>
                    <div>{t("Room") + " " + room.roomNumber}</div>
                    <div className="d-flex">
                        <div className="pr-1">{room.roomType + " - "}</div>
                        <CleanTypeRenderItem style={{color: !!routine?.color ? ColorUtils.getContrastColor(routine.color) : 'black'}} />
                    </div>
                    {!!HKReport?.markingReport && 
                        HKReport?.markingReport.cleanType !== HKReport.selectedCleanType
                        && <p className="font-weight-bold" style={{color: !!routine?.color ? ColorUtils.getContrastColor(routine.color) : 'black'}}>
                            {t("This Clean type doesn't match the marking clean type '{{cleanType}}'", {cleanType: HKReport.markingReport.cleanType})}
                            </p>}
                </div>

                {(HKReport.readonly === true || HKReport.auditMode) &&
                    <div className="d-flex flex-column ml-2">
                        <div style={{fontWeight: "bold"}}>{t('History Report')}</div>
                        <div>{t("Staff Member") + `: ${HKReport?.staffMember?.firstName} ${HKReport?.staffMember?.lastName}`}</div>
                        <div>{t("Completed") + `: ${moment(HKReport?.reportDate).format('YYYY-MM-DD HH:mm:ss')}`}</div>
                        <div>{t("Duration") + ": " + (!!HKReport?.reportDate && !!HKReport?.startDate ? `${Math.round(moment.duration(moment(HKReport?.reportDate).diff(moment(HKReport?.startDate))).asMinutes()*100)/100}` : "")}</div>
                    </div>}


                {!HKReport.auditMode &&<><div className="ml-auto mr-2 phone-hide">
                    {t('Post-Clean Notes:')}
                </div>
                <div className="w-50 text-truncate phone-hide" style={{maxHeight: '100px', overflowY: 'auto'}}>
                    {_addLineBreaks((resident.notes ?? []).filter(n => n.type === NoteType.PostClean).map(n => n.notification).join("\n"))}
                </div></>}

                {HKReport.auditMode &&<div className="ml-auto p-2 d-flex flex-row dashboard_AuditReport">
                    <div className="d-flex flex-column" style={{color: "black"}}>
                        <div className="d-flex flex-row">
                            <div className='dasboard_ReportTitle'>{state.markingReports ? t('Pre-Audit Marking') : t('Audit Report')}</div>
                            <div className='mr-auto'></div>
                        </div>
                        <div className="d-flex flex-row mr-4">
                            <div className="d-flex flex-column mr-4 dasboard_Header">                                
                                <div>{t("Auditor") + `:`}</div>
                                <div>{t("Date") + `:`}</div>
                                <div>{t("Marking") + `:`}</div>
                                <div>{t("Started") + `:`}</div>
                                <div>{t("Completed") + ": "}</div>
                            </div>
                            <div className="d-flex flex-column ml-2 mr-4">                            
                                <div>{!!HKReport?.auditReport?.auditor ? `${HKReport?.auditReport?.auditor?.firstName} ${HKReport?.auditReport?.auditor?.lastName}` : t('Not Staff Member')}</div>
                                <div>{`${moment(HKReport?.auditReport?.startDate).format('YYYY-MM-DD')}`}</div>
                                <div>{!!HKReport?.auditReport?.markingDate 
                                    ? `${moment(HKReport?.auditReport?.markingDate).format(
                                        moment(HKReport?.auditReport?.markingDate).format('YYYY-MM-DD') === moment(HKReport?.auditReport?.startDate).format('YYYY-MM-DD') ? 'HH:mm' : 'YYYY-MM-DD HH:mm')}`
                                    : ' - '}
                                </div>
                                {!HKReport?.auditMarking &&
                                <>
                                    <div>{`${moment(HKReport?.auditReport?.startDate).format('HH:mm')}`}</div>
                                    <div className={classnames({"dasboard_NotComplete": !HKReport?.auditReport?.reportDate})}>{`${moment(HKReport?.auditReport?.reportDate ?? HKReport?.auditReport?.startDate).format('HH:mm')}`}</div>
                                </>}
                            </div>
                        </div>
                    </div>

                    <div className="mr-2 dasboard_Header">
                        {t('Method:')}
                    </div>

                    <div className="d-flex flex-column ml-2" style={{color: "black"}}>
                        <Form.Check type="checkbox" id="directObservationMethod"
                                label={t("Direct observation")}  
                                onChange={(e: any) => updateAudit({directObservationMethod: !HKReport?.auditReport?.directObservationMethod})} 
                                checked={HKReport?.auditReport?.directObservationMethod} />
                        <Form.Check type="checkbox" id="fluorescentGelMethod"
                                label={t("Fluorescent gel")}  disabled={HKReport?.auditMarking || !!HKReport?.markingReport}
                                onChange={(e: any) => updateAudit({fluorescentGelMethod: !HKReport?.auditReport?.fluorescentGelMethod})} 
                                checked={HKReport?.auditReport?.fluorescentGelMethod} />
                        <Form.Check type="checkbox" id="atpSystemMethod"
                                label={t("ATP system")}  
                                onChange={(e: any) => updateAudit({atpSystemMethod: !HKReport?.auditReport?.atpSystemMethod})} 
                                checked={HKReport?.auditReport?.atpSystemMethod} />
                        <Form.Check type="checkbox" id="swabCulturesMethod"
                                label={t("Swab cultures")}  
                                onChange={(e: any) => updateAudit({swabCulturesMethod: !HKReport?.auditReport?.swabCulturesMethod})} 
                                checked={HKReport?.auditReport?.swabCulturesMethod} />
                        <Form.Check type="checkbox" id="agarSlideCulturesMethod"
                                label={t("Agar slide cultures")}  
                                onChange={(e: any) => updateAudit({agarSlideCulturesMethod: !HKReport?.auditReport?.agarSlideCulturesMethod})} 
                                checked={HKReport?.auditReport?.agarSlideCulturesMethod} />
                    </div>
                </div>}
            </div>
            <div className="groupArea">
                {groupList.map(opGroup => {
                    if (opGroup.group === selectedGroup) return (<div key={opGroup.group} className={classnames('groupTitleCurrent unselectable', opGroup.status)} onClick={() => checkAllInGroup(opGroup)}>{opGroup.group}</div>)
                    return (<div key={opGroup.group} className={classnames('groupTitle unselectable', opGroup.status)} onClick={() => selectGroup(opGroup.group)}>{opGroup.group}</div>)
                })}
            </div>
            <div className="scrollArea" ref={scrollView}>
                <div className="d-flex">
                    {operations?.map((operation) => {
                        const style = operation.skipped !== true ? (operation.checked === undefined ? "pending" : operation.checked === true ? "complete" : "notrequired") : "incomplete"
                        const flipStyle = style === "complete" ? "notrequired" : "complete"
                        const id = "op_" + operation.index
                        return (

                            HKReport.auditMode 
                                ? (<AuditOperationBox key={id} operation={operation} 
                                    styleClass={style} flipStyleClass={flipStyle} 
                                    onSetAuditStatus={(auditStatus: HKAuditOperationStatus) => setOperationAuditStatus(operation.index, auditStatus)}
                                    onShowTaskDetails={() => _showTaskDetails(state, operation)}
                                    auditMarkingMode={HKReport.auditMarking} onSetAuditMarking={isMarked => setOperationAuditMarked(operation.index, isMarked)} />)
                                : 
                                (<OperationBox key={id} operation={operation} isSelected={operation === nextOperation} 
                                    styleClass={style} flipStyleClass={flipStyle} 
                                    onClick={() => checkItem(operation, operation.index)}
                                    onShowTaskDetails={() => _showTaskDetails(state, operation)} />)
                        )
                    })}
                </div>
                <div className="scrollAreaPlaceholder"></div>
            </div>
            { !state.showShortcuts || HKReport.readonly || !(shortcuts.length > 0) || HKReport.auditMode ? null :
                <>
                    <div className="overlay unselectable" onClick={(e) => _hideShortcuts()}><h1>Tap to Continue</h1></div>
                    <div className="fixedBottom">
                        <div className="d-flex">
                            <ShortcutBox id="cancel" text="Cancel" icon="feather icon-delete" color="rgb(255, 162, 253)" onClick={(e) => _cancel()} />
                            <div className="scrollArea d-flex">
                                <ShortcutBox id="occupied" text="Room occupied." icon="sprite-residents" color="rgb(250, 250, 250)" onClick={(e) => _roomOccupied()} />
                                {shortcuts.map((routine, index) =>
                                        <ShortcutBox
                                            key={"sc-" + index}
                                            id={index + "-" + routine.shortcut?.order}
                                            text={routine.cleanType}
                                            icon={routine.shortcut?.icon}
                                            color={routine.shortcut?.color}
                                            onClick={(e) => switchClean(routine) }
                                        />
                                    )}
                            </div>
                            <ShortcutBox id="check-clean" className="ml-auto" text="Room checked, CLEAN."
                                icon="feather icon-check-circle" color="rgb(197, 223, 184)" onClick={(e) => _roomCheckedClean()} />
                        </div>
                    </div>
                </>
            }
            <div className="fixedBackground"></div>
            <div className="fixedBottomLeft lightPink" onClick={(e) => _cancel()}><h5>{HKReport.readonly ? t('Close') : t("Cancel")}</h5></div>            
            <div className={classnames("phone-hide fixedDetailsButton lightBlue", {disabledButton: (nextOperation?.detailImages || []).length === 0})}
                    onClick={(e) => {if(nextOperation) _showTaskDetails(state, nextOperation)}}>
                <i className={classnames("fa fa-question-circle-o fa-3x")} title={t("Task Details")} />
            </div>            

            <div className="fixedComment">
                <div className='position-relative'>
                    <Form.Control as="textarea" rows={3} id="reportComment" readOnly className="fixedCommentArea"
                        value={(HKReport.comment || '') + (!!HKReport.supervisorNote ? (((!!HKReport.comment ? '\n' : '') +  t('Auditor Note')) + '\n' + HKReport.supervisorNote) : '')} 
                        onClick={() => _editComment()} />
                    <div className="fixedCommentPlaceholder text-muted font-weight-light" onClick={() => _editComment()}>{t('Housekeeper comments & photos')}</div>
                </div>
                    
                {HKReport.auditMode &&
                <div className='position-relative'>
                    <Form.Control as="textarea" rows={3} id="reportComment" readOnly className="fixedCommentArea mt-1"
                        value={(HKReport.auditReport?.comment || '')} 
                        onClick={() => _editAuditComment()} />
                    <div className="fixedCommentPlaceholder text-muted font-weight-light" onClick={() => _editAuditComment()}>{t('Auditor comments & photos')}</div>
                </div>}
                {isSupervisor && <Button variant="link" style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }} className={classnames("phone-hide fixedSupervisorNoteLink")} onClick={e => _editSupervisorNote()}>{t('Auditor Note')}</Button>}

            </div>
            <div className={classnames("fixedBottomRight lightBlue", {disabledButton: HKReport.readonly})} onClick={(e) => _save(reportState)}><h5>{t("Save")}</h5></div>
        </>
    );
}

export default HousekeepingDashboard;
